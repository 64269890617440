<template>
  <!-- 学习数据页面 -->
  <div class="padding24 color_white" id="study_data">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div v-if="$route.meta.isShow">
        <!-- 顶部 -->
        <div class="top">
          <div class="top_left"></div>
          <div class="top_rigiht">
            <span> 学习时间 : <a-range-picker @change="onChangeDate" /></span>
            <span>
              至少学完 :
              <a-input
                v-model="getData.finishLessonsCount"
                placeholder="请输入课节数"
                @pressEnter="searchClick"
              >
              </a-input>
            </span>
            <span>
              课程名称 :
              <a-input
                v-model="getData.courseName"
                placeholder="请输入课程名称"
                @pressEnter="searchClick"
              >
              </a-input>
            </span>
            <span>
              <a-input
                v-model="getData.userName"
                placeholder="请输入用户账号"
                style="width: 200px"
                @pressEnter="searchClick"
              >
                <a-icon slot="prefix" type="search" />
              </a-input>
            </span>
            <a-button
              icon="search"
              type="primary"
              @click="searchClick"
              :disabled="isDisableSearch"
            >
              搜索
            </a-button>
          </div>
        </div>
        <div class="btns">
          <a-button
            v-if="isShowCourse"
            icon="export"
            type="primary"
            @click="exportCourseClick"
            :disabled="isDisableCourse"
          >
            导出课程学习数据
          </a-button>
          <a-button
            v-if="isShowLessons"
            icon="export"
            type="primary"
            @click="exportLessonsClick"
            :disabled="isDisableLessons"
          >
            导出课节学习数据
          </a-button>
        </div>
        <!-- 表格 -->
        <a-table
          :columns="columns"
          :data-source="tableData"
          :pagination="false"
          :loading="tableLoading"
        >
          <span slot="userName" slot-scope="userName, record">
            <span @click="userClick(record)">{{ record.userName }} </span>
          </span>
          <img
            v-viewer
            class="avatar"
            slot="imageUrl"
            slot-scope="imageUrl, record"
            :src="record.imageUrl"
          />
          <span slot="operation" slot-scope="operation, record">
            <a v-if="isShowDetail" @click="detailClick(record)">详情</a>
          </span>
        </a-table>
        <!-- 分页功能 -->
        <MyPagination
          :count="count"
          :pageNo="getData.pageNo"
          @showSizeChangeFn="showSizeChangeFn"
          v-show="!tableLoading"
        />
        <!-- 返回顶部 -->
        <a-back-top :target="targetFn" :visibilityHeight="100" />
      </div>
    </a-spin>
    <router-view />
  </div>
</template>

<script>
import {
  QueryLearnDataResultApi,
  ExportLearnDataResultApi,
  ExportLearnDataDescResultApi,
} from "@/request/api/crm";
import MyPagination from "@/components/pagination/MyPagination";
import { codeFn } from "@/utils/tools";
export default {
  components: { MyPagination },
  created() {
    this.QueryLearnDataResultFn();
    if (codeFn("/admin/course/learn/queryLearnDataDescResult")) {
      this.isShowDetail = true;
    }
    if (codeFn("/admin/course/learn/exportLearnDataResult")) {
      this.isShowCourse = true;
    }
    if (codeFn("/admin/course/learn/exportLearnDataDescResult")) {
      this.isShowLessons = true;
    }
    if (codeFn("/admin/app/user/queryAdminUserInfos")) {
      this.isShowUserList = true;
    }
  },
  data() {
    return {
      spinning: false,
      tip: "",
      count: 0,
      getData: {
        pageNo: 1,
        pageSize: 20,
        finishLessonsCount: "", // 学完课节数
        courseName: "", // 课程名称
        userName: "",
      },
      isDisableCourse: false,
      isDisableLessons: false,
      isDisableSearch: false,
      tableLoading: true,
      columns: [
        {
          title: "账号",
          dataIndex: "userName",
          key: "userName",
          width: "10%",
          scopedSlots: { customRender: "userName" },
        },
        {
          title: "头像",
          dataIndex: "imageUrl",
          key: "imageUrl",
          width: "6%",
          scopedSlots: { customRender: "imageUrl" },
        },
        {
          title: "昵称",
          dataIndex: "nickName",
          key: "nickName",
          width: "10%",
        },
        {
          title: "性别",
          dataIndex: "sexText",
          key: "sexText",
          width: "5%",
        },
        {
          title: "课程名称",
          dataIndex: "courseName",
          key: "courseName",
          width: "20%",
        },
        {
          title: "学习时长(min)",
          dataIndex: "learnTimeFormat",
          key: "learnTimeFormat",
          width: "6%",
        },
        {
          title: "学完课节数",
          dataIndex: "finishLessonsCount",
          key: "finishLessonsCount",
          width: "7%",
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          width: "5%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      isShowDetail: false,
      isShowCourse: false,
      isShowLessons: false,
      isShowUserList: false, // 用户管理列表
    };
  },
  methods: {
    // 日期改变
    onChangeDate(date, dateString) {
      if (dateString[0] != "") {
        this.getData.startTime = dateString[0] + " 00:00:00";
        this.getData.endTime = dateString[1] + " 23:59:59";
      } else {
        this.getData.startTime = "";
        this.getData.endTime = "";
      }
    },
    // 导出课程数据
    exportCourseClick() {
      this.isDisableCourse = true;
      setTimeout(() => {
        this.isDisableCourse = false;
      }, 3000);
      window.location.href = `${ExportLearnDataResultApi}`;
    },
    // 导出课节数据
    exportLessonsClick() {
      this.isDisableLessons = true;
      setTimeout(() => {
        this.isDisableLessons = false;
      }, 3000);
      window.location.href = `${ExportLearnDataDescResultApi}`;
    },
    // 搜索的按钮
    searchClick() {
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.getData.pageNo = 1;
      this.QueryLearnDataResultFn();
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.getData.pageNo = current;
      this.getData.pageSize = pageSize;
      this.QueryLearnDataResultFn();
    },
    // 点击账号
    userClick(record) {
      if (this.isShowUserList && record.userName != "——") {
        localStorage.setItem("phone", record.userName);
        location.href = "/crm/clientManage";
      }
    },
    // 详情按钮
    detailClick(record) {
      this.$router.push({
        path: "/crm/studyData/studyDetail",
        query: {
          nickName: record.nickName,
          userName: record.userName,
          courseName: record.courseName,
          courseNo: record.courseNo,
          userNo: record.userNo,
        },
      });
    },
    // 学习数据列表
    QueryLearnDataResultFn() {
      this.tableLoading = true;
      QueryLearnDataResultApi(this.getData).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          data.data.forEach((item, index) => {
            item.key = index;
            item.userName = item.userName == "" ? "——" : item.userName;
          });
          this.tableData = data.data;
          this.count = data.count;
        }
      });
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#study_data");
    },
  },
};
</script>
 
<style lang="less" scoped>
#study_data {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      margin-right: 10px;
      /deep/.ant-input {
        width: 200px;
      }
      /deep/.ant-calendar-picker-input {
        width: 300px;
      }
    }
  }
}
.btns {
  margin-top: 20px;
  /deep/.ant-btn {
    margin-right: 20px;
  }
}
.avatar {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
</style>